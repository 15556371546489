export default function useLocalStorage(key, defaultValue = null) {
  return computed({
    get: key => {
      if (process.server) {
        return defaultValue;
      }

      let _item = defaultValue;
      const item = localStorage.getItem(key);
      if (item) {
        try {
          _item = JSON.parse(item);
        } catch {
          // noop
        }
      }
      return _item;
    },
    set: val => {
      if (process.server) {
        return;
      }
      let item;

      try {
        item = JSON.stringify(val);
      } catch {
        // noop
      }
      if (item === undefined) {
        return;
      }
      localStorage.setItem(key, item);
    },
  });
}
